@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

@media screen and (orientation: landscape) {
  .mobileDistance {
    display: none;
  }
  .cookie-modal{
    margin-top: 50px;
  }
  .blm-pic{
    object-fit: cover;
    max-width: 100px;
    max-height: 140px;
  }
  .webLocation a {
    color: #212529 !important;
  }
  .mobileLocation {
    display: none !important;
  }
  .phoneIcon {
    display: none !important;
  }
  .phoneNum a {
    color: #212529 !important;
  }
  .input-group {
    max-width: 70%;
    margin-left: 15%;
  }
  .actualBar {
    width: 33.5% !important;
    margin-left: auto !important;
    margin-right: auto;
  }
  .fixedFilters {
    position: fixed;
    z-index: 3;
    left: 0px;
    top: 110px;
    width: 100%;
    right: 0px;
  }

  .GoogleMap {
    height: 75vh !important;
    width: 33.33% !important;
  }

  .GMapContainer {
    position: fixed !important;
    left: 65% !important;
  }
  .icon-navs {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .annaImage{
    height: 34vh!important;
  }
  .directionsIcon {
    font-size: 3em !important;
    margin-left: -50%;
    color: #0f9d58 !important;
  }
  .blm-pic{
    margin-left: 32%;
    object-fit: cover;
    max-width: 100px;
    max-height: 140px;
  }
  .cookie-modal{
    margin-top: 120px;
  }
  .mobileLocation {
    margin-left: auto;
    margin-right: auto;
  }

  .webLocation {
    display: none !important;
  }
  .phoneText {
    display: none;
  }
  .phoneIcon {
    font-size: 3em !important;
    color: #43a5f5 !important;
  }
  .flag-icon {
    font-size: 2em;
  }

  @media only screen and (max-width: 562px) {
    nav.color-nav.navbar.navbar-expand-lg.navbar-light.tallNav {
      padding-top: 10px !important;
      margin-top: 0px !important;
      padding-bottom: 200px !important;
    }
    .GoogleMap {
      margin-top: 30px !important;
    }

    .jumbotron h1 {
      margin-top: 10%;
      margin-bottom: 1% !important;
    }
    .siteDescription{
      margin-bottom: 10%;
    }
  }

  @media only screen and (min-width: 563px) {
    nav.color-nav.navbar.navbar-expand-lg.navbar-light.tallNav {
      padding-top: 10px !important;
      margin-top: 0px !important;
      padding-bottom: 170px !important;
    }
    .GoogleMap {
      margin-top: 30px !important;
    }
  }

  .searchBar {
    margin-top: 25px !important;
    padding-top: 0px !important;
  }

  #responsive-navbar-nav {
    display: inherit !important;
  }

  .GoogleMap {
    height: 33vh !important;
    width: 100% !important;
  }
  /*.GMapContainer{*/
  /*  position: fixed!important;*/
  /*  left: 0%!important;*/
  /*  maxWidth: 100%!important;*/
  /*}*/

  .GMapContainer {
    display: none;
  }

  .cardImageCol {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 5px;
  }

  .card-img {
    height: 20vh !important;
  }
  /*.results{*/
  /*  margin-top: 32vh!important;;*/
  /*}*/

  .results {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  button.collapsed {
    display: none;
  }

  .word-navs {
    display: none !important;
  }

  .actualBar {
    margin-top: 0px !important;
    padding-top: 0px !important;
    height: 10px !important;
    width: 80% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .actualBar input {
    /*height: 10px!important;*/
  }
  .fixedFilters {
    position: fixed;
    z-index: 3;
    left: 0px;
    top: 120px;
    width: 90% !important;
    right: 0px;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .filters {
    display: block !important;
  }

  .filterButton {
    margin-top: 10px !important;
    font-size: 0.8em !important;
  }

  @media only screen and (max-width: 575px) {
    .card-deck {
      padding: 0 80px 0 80px;
    }
    .card-img.card-img-top {
      height: 100%;
    }
    .biodeck{
      padding: 0 20px 0 20px;
    }
  }

  @media only screen and (max-width: 415px){
    input.form-control::placeholder{
      font-size:.9em!important;
    }
  }

  @media only screen and (max-width: 394px){
    input.form-control::placeholder{
      font-size:.85em!important;
    }
  }

  @media only screen and (max-width: 385px){
    input.form-control::placeholder{
      font-size:.8em!important;
    }
  }

  @media only screen and (max-width: 360px){
    input.form-control::placeholder{
      font-size:.85em!important;
    }
  }
  


  @media only screen and (max-width: 357px) {
    .nav-link,.dropdown{
      margin-right: 0px!important;
    }
  }
  .searchByCuisine {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .carousel-control-next {
    display: none !important;
  }

  .carousel-control-prev {
    display: none !important;
  }

  .carousel-indicators li {
    background-color: black !important;
  }
}

.CardWebsiteTitle{
  color: black;
}

.CardWebsiteTitle:hover{
  color: black;
}

.carousel-indicators {
  bottom: -40px !important;
}

html,
body {
  font-family: "Maven Pro", sans-serif !important;
}

.buttonImageCard {
  padding: 0px !important;
}

.cuisineButton {
  border-radius: 0px 0px 40px 40px !important;
  height: 60px;
  background-color: #42a5f5 !important;
  color: white !important;
}

.darkenCard:hover {
  /*background-color: black!important;*/
}

.darkenCard:hover .cuisineButton {
  background-color: #0077c2 !important;
}
.darkenCard:hover .card-img-top {
  filter: brightness(70%);
  cursor: pointer;
}

.cuisineButton:hover {
  background-color: #42a5f5 !important;
  color: white;
}

.btn-primary {
  background-color: #42a5f5;
  border-color: #42a5f5;
}

.search-button {
  background-color: #42a5f5 !important;
  border-color: #42a5f5 !important;
  border-radius: 0px 40px 40px 0px !important;
}

.flag-icon {
  cursor: pointer;
}

.toast-body {
  font-size: large;
  font-family: "Maven Pro", sans-serif;
}
.Toastify__toast {
  border-radius: 20px;
}

.btn-primary:hover {
  background-color: #0077c2 !important;
  border-color: #0077c2 !important;
}

.navbar-light .navbar-toggler {
  z-index: 100;
}

.nav-link {
  z-index: 100;
}

.filters {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.searchBar {
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 40px;
  left: 0px;
}
nav.color-nav.navbar.navbar-expand-lg.navbar-light.tallNav {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 80px;
  box-shadow: 0 0.05rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.resultsAndMap {
  padding-top: 180px;
}

.search-button {
  background-color: #42a5f5;
  height: 49px !important;
  border-radius: 0px 300px 300px 0px;
  color: white;
  border: #42a5f5;
}

.search-button:hover {
  background-color: #0077c2 !important;
  border-color: #0077c2 !important;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
}

.filters > * {
  margin-right: 1rem;
  margin-left: 1rem;
}
.jumbotron {
  background: url("./images/banner.png") no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-banner {
  background: url("./images/aboutbanner.png") no-repeat !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.jumbotron h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
  color: black;
  margin-bottom: 1%;
  text-align: center;
}

.siteDescription{
  margin-bottom: 5%;
}



.color-nav {
  background-color: white;
}

.color-foot {
  background-color: #b22222;
}

.red {
  color: #b22222;
}

.unpressed-button {
  background-color: darkgray !important;
  border-color: black !important;
  border-width: 1.5px !important;
}
.unpressed-button:hover {
  background-color: gray !important;
  border-color: black !important;
}

.pressed-button {
  background-color: #b22322 !important;
  border-color: black !important;
}
.pressed-button:hover {
  border-color: black !important;
}

.pressed-button {
  background-color: #42a5f5 !important;
  border-color: black !important;
  border-width: 1.5px !important;
}

.dropdown-item .basic-nav-dropdown {
  z-index: 1000;
}

.black {
  color: black;
  font-weight: 500;
}

.dropdown-item.active {
  background-color: white;
}

.dropdown-item:active {
  background-color: white;
}

.text-center {
  align-items: center;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
  border-radius: 40px 40px 0px 0px !important;
}

.card-img-left {
  object-fit: cover;
  width: 100%;
  height: 12vw;
  border-radius: 1rem !important;
}

.noresults-img {
  object-fit: cover;
  margin-top: 10%;
  width: 80%;
}
.servererror-img {
  object-fit: cover;
  margin-top: 10%;
  width: 70%;
}

a.carousel-control-next {
  width: 0px;
}

a.carousel-control-prev {
  width: 0px;
}
.no-results,
.error {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .no-results,
  .error {
    margin-bottom: 10%;
  }

}

@media screen and (min-width: 850px){
  .biodeck{
    padding-left: 7.5%;
    padding-right:7.5%;
  }
}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .no-results,
  .error {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}

.profile-pic {
  object-fit: cover;
  max-width: 200px;
  width: 100%;
}


.disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
}

.shareDropdown .dropdown-toggle::after{
  display: none;
}

@media screen and (min-width: 1200px){
    .leftDisclaimer{
      float: left;
    }
  .disclaimer{
    width: 50%;
    margin-bottom: 10px;
  }
}

@media  screen and (max-width: 1199px){
  .rightDisclaimer{
    padding-top: 0px;
  }
}

.siteDescription{
  font-size: 1.32em;
}

.icon-navs.navbar-nav{
  font-size: 1.5em;
}
